import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Steel Beams Across Corridor' },
    { img: data.i2, caption: 'W4X13 Lintel Beams Across Corridor' },
    { img: data.i3, caption: 'W4X13 Lintel Beams Across Corridor' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: EPICORE MSR Structural Steel Beams / Lintels" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>EPICORE MSR Structural Steel Beams / Lintels</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>The Infinity Structural System is really a hybrid system consisting
            of load-bearing metal stud walls, the Epicore Composite Floor System,
            Epicore MSR Slab-beams, light-gage beams/headers and structural steel
            beams and columns (red iron).  Infinity will incorporate whatever is most
            economical and user-friendly to ensure a smooth & successful project.</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/cantilevers/">
                Next Page: Cantilevers
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="/system-overview/rebar-headers/">
                Prev Page: Rebar Headers
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/steel-beams/gallery/01-LinBe040_(33887).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i2: file(relativePath: { eq: "system-overview/steel-beams/gallery/02-MSRDk890_(41363).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i3: file(relativePath: { eq: "system-overview/steel-beams/gallery/03-MSRDk880_(41361).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`


